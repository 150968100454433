import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Sebastian Lotzer – ein überzeugter Laienprediger</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-6">
                            <figure>
                                <img src={"/images/Memmingen/lotzer.jpg"} alt=""/>
                            </figure>
                        </div>
                        <div className="col-6">
                            <p>
                                Sebastian Lotzer wurde um 1490 in Horb am Neckar in einer angesehenen Familie geboren.
                                Seine
                                Biografie ist nur lückenhaft überliefert. Er erlernte das Handwerk des Kürschners, ging
                                auf
                                Wanderschaft und kam so nach Memmingen. Hier heiratete er die Kramerstochter Margret
                                Weigelin,
                                deren Geschäft er dann führte.
                            </p>
                            <p>
                                Er setzte sich für die Ideen der Reformation ein und war Christoph Schappeler, dem
                                Prediger der
                                Stadtkirche, eng verbunden. Zwischen 1523 und 1525 verfasste Lotzer mehrere
                                reformatorische
                                Schriften. Er fordert unter anderem zum Kauf des Neuen Testaments auf, es sei die
                                „rechte lebendige
                                Speise für [die] Seelen“. 1525 unterstützte er die Forderungen der Bauern und war als
                                Feldschreiber
                                des Baltringer Haufens maßgeblich an der Abfassung der Zwölf Artikel und der
                                Bundesordnung
                                beteiligt. Nach dem Sieg des Schwäbischen Bundes im Bauernkrieg musste er im April 1525
                                aus Memmingen
                                fliehen. Sein Sterbeort und -datum sind unbekannt.
                            </p>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
